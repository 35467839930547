/* eslint-disable no-undef, react/prop-types, react/no-unused-state */
import React, { Component, useState } from 'react';
import { Link, navigate } from 'gatsby';
import style from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import { puffInAnimation, subtitleFadeIn, slideInBack, slideInLeft, slideInRight, slideInTop, slideOutRight } from '../../style/animations';
import { disableBrowserStyling, displayInline, bulldogsColor1, bulldogsColor2 } from '../../style/genericStyling';
import { navBasic, exBarNav, exBarItem, exBarContent, exBarLink, exBarBar, barHoverDark } from '../../style/mouseOverUnderline';
import feather from '../../utils/feather';
import colors from '../../utils/colors';
import MobileNav from './mobile';
import media from '../../utils/media';
import { Box, BoxVisible } from '../../style/basicStyle';
import { shadedPanel } from '../../style/panelStyling';
import NavBarLink from './NavBarLink/index';
import NavBarLinkMobile from './NavBarLinkMobile/index';
import { pages } from '../../vars/pages';

const basicNav = css`
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  background-color: rgb(255, 255, 255, 0.5);
  -webkit-box-shadow: 0px 1px 20px 0px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 1px 20px 0px rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 20px 0px rgba(255, 255, 255, 1);
  margin: 0;
  width: 100%;
  list-style-type: none;
  z-index: 9998;
  height: 3.5rem;

  & ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;

const fullNav = css`
  display: none;
  & li {
    margin: 0;
  }

  & li img {
    display: inline-block;
    vertical-align: middle;
  }
  & ul div {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;

    & li {
      margin-left: 1.25rem;
      & div {
        & div {
          & div {
            & div {
              & div {
                & div {
                  & ul {
                    & li {
                      margin-left: 0rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ${media.mid`
    display: flex;
  `};
`;

const fullNavDark = css`
  color: ${colors.primary};
  background-color: ${colors.secondary};
  -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 1);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 1);
`;

// Styles for the mobile View of the navigation
const mobileNav = css`
  background-color: ${colors.secondary};
  color: ${colors.primary};

  & li {
    margin: 0;
  }

  & li:last-child {
    margin-left: auto;
    font-weight: 600;
    cursor: pointer;
  }

  & li img {
    display: block;
    margin: auto;
  }
  ${media.mid`
    display: none;
  `};
`;

const MobileNavMenuStyle = css`
  padding-inline-start: 0;
`;

const menuIconStyle = css`
  vertical-align: top;
  margin-top: -4px;
`;

const parentIconStyle = css`
  display: inline !important;
  margin-left: 10px;
`;

const BulldogsLogo = style.div`
  margin-right:10px;
  margin-top:-8px;
  margin-bottom:-10px;
  display: inline-block;
  padding-top:0px;
  padding-right:5px;
  width: 28px;
  height: 28px;
  font: 30px Times New Roman, sans-serif;
  margin-bottom:-4px;
  vertical-align:text-bottom;
  text-shadow: 1px 1px 2px #00000040;
  ${media.small`
    margin-bottom:-2px;
  `};
  ${media.mid`
    margin-bottom:0px;
    display: inline;
    width: 40px;
    height: 40px;
    font-size: 38px;
    padding-top: 2px;
    padding-right: 2px;
  `};
`;

const logoMarginFix = css`
  margin-top: 0px !important;
`;

const logoHover = css`
   {
    & a {
      width: 80%;
      margin-left: 50px;
    }
    & :hover {
      transition: 0.25s ease;
      opacity: 1;
      text-decoration: none !important;
    }
  }
`;

const shopButtonMobileStyle = css`
  background-color: transparent;
  color: white;
  border-style: none;
  cursor: pointer;
`;

const logoStyling = css`
  cursor: pointer;
`;

const snipcartStyleDesktop = {
  backgroundColor: colors.tertiary,
  color: 'white',
  borderRadius: '5px 0px 0px 5px',
  top: '7%',
  right: '0px',
  fontSize: '16px',
  position: 'fixed',
  zIndex: '999999',
  cursor: 'pointer',
  transition: '.5s',
  padding: '0px'
};

const hideCart = css`
  display: none !important;
`;

class Navigation extends Component {
  static logoClickHandler() {
    navigate('/');
  }

  constructor(props) {
    super(props);
    this.state = {
      mobileFadeOut: false,
      mobileActive: false
    };
    this.toggleNav = this.toggleNav.bind(this);
    Navigation.logoClickHandler = Navigation.logoClickHandler.bind(this);
  }

  toggleNav() {
    const { state } = this;
    if (state.mobileActive) {
      this.setState({ mobileFadeOut: true });
      setTimeout(() => {
        this.setState({ mobileActive: false, mobileFadeOut: false });
      }, 500);
    } else {
      this.setState({ mobileActive: true });
    }
  }

  render() {
    const { state } = this;
    const { props } = this;
    const { showExperimental } = props;
    const { showCart } = props;
    const { authUser } = props;
    return (
      <nav>
        <BoxVisible width="100%" px={[3, 3, 4]} css={[basicNav, fullNav, props.navDarkActive && fullNavDark]}>
          <ul css={[navBasic, exBarNav]}>
            <li css={[navBasic, exBarItem, logoHover]}>
              <div css={[navBasic, exBarContent, logoHover, slideInLeft]}>
                <BulldogsLogo css={[disableBrowserStyling, logoStyling]} onClick={() => Navigation.logoClickHandler()} role="button" tabIndex="0" onKeyPress={() => Navigation.logoClickHandler()}>
                  <StaticImage css={shadedPanel} style={{ width: '40px', height: '40px' }} src='../../../bulldogshockeyassets/images/index/bulldogsLogoSmallTransparentBanner.png' alt="default profile image" />
                </BulldogsLogo>
                <Box>FLORIDA</Box>
                <Box css={[bulldogsColor2]} style={{ fontWeight: 'bold', marginLeft: '0.2em' }} fontSize={[4]}>
                  BULLDOGS
                </Box>
              </div>
              <Link to="/" css={[navBasic, exBarLink]}>
                <span css={[navBasic, exBarBar, props.navDarkActive && barHoverDark]} />
              </Link>
            </li>
            <div css={slideInRight}>
              {pages.map(
                (page, index) =>
                  (!page.requireAuth || (authUser && !page.hideWithAuth)) && (
                    <NavBarLink
                      hidden={page.hidden}
                      externalLink={page.externalLink}
                      styling={page.styling}
                      childLinkStyling={page.childLinkStyling}
                      linkNameSignedIn={page.linkNameSignedIn}
                      authUser={authUser}
                      toggleNav={this.toggleNav}
                      key={page.linkName + index}
                      childLinks={page.childLinks}
                      linkName={page.linkName}
                      requireAuth={page.requireAuth}
                      requireAdmin={page.requireAdmin}
                      requireOrders={page.requireOrders}
                      requireTournaments={page.requireTournaments}
                      requireTuition={page.requireTuition}
                      requireEvents={page.requireEvents}
                      hideWithAuth={page.hideWithAuth}
                      showExperimental={showExperimental}
                      link={page.link}
                      navDarkActive={props.navDarkActive}
                    />
                  )
              )}
            </div>
          </ul>
          <Box css={[!showCart && hideCart, snipcartStyleDesktop]}>
            <button
              style={{ cursor: 'pointer', borderStyle: 'none', backgroundColor: colors.tertiary, paddingTop: '5px', paddingBottom: '5px', paddingLeft: '10px', paddingRight: '10px' }}
              type="button"
              className="snipcart-checkout"
            >
              <span css={{ marginLeft: '6px' }} className="snipcart-items-count" />
              {feather('shopping-cart', ['35', '35'])}
            </button>
          </Box>
        </BoxVisible>
        <Box width="100%" px={[3, 3, 4]} css={[basicNav, mobileNav]}>
          <ul>
            <li>
              <Link to="/">
                <Box wrap="nowrap">
                  <BulldogsLogo
                    css={[disableBrowserStyling, logoStyling, logoMarginFix]}
                    onClick={() => Navigation.logoClickHandler()}
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => Navigation.logoClickHandler()}
                  >
                    <StaticImage css={shadedPanel} style={{ width: '28px', height: '28px' }} src='../../../bulldogshockeyassets/images/index/bulldogsLogoSmallTransparentBanner.png' alt="default profile image" />
                  </BulldogsLogo>
                  {/* eslint-disable-next-line */}
                  <span>FLORIDA</span>{' '}
                  <span css={[bulldogsColor2]} style={{ fontWeight: 'bold' }}>
                    BULLDOGS
                  </span>
                </Box>
              </Link>
            </li>
            <li>
              <button css={[disableBrowserStyling, displayInline, shopButtonMobileStyle, !showCart && hideCart]} type="button" className="snipcart-checkout">
                <span css={{ color: colors.tertiary, fontSize: '15px', position: 'absolute', marginLeft: '20px', marginBottom: '10px', marginTop: '-12px' }} className="snipcart-items-count" />
                {feather('shopping-cart', ['25', '25'], menuIconStyle.styles, parentIconStyle)}
              </button>
              <span css={[disableBrowserStyling, displayInline]} onClick={() => this.toggleNav()} role="button" tabIndex="0" onKeyPress={() => this.toggleNav()}>
                {feather('menu', ['25', '25'], menuIconStyle.styles, parentIconStyle)}
              </span>
            </li>
          </ul>
        </Box>
        {state.mobileActive && (
          <MobileNav toggleNav={this.toggleNav} mobileFadeOut={state.mobileFadeOut}>
            <ul css={[MobileNavMenuStyle]}>
              {pages.map((page, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <NavBarLinkMobile
                  hidden={page.hidden}
                  styling={page.styling}
                  childLinkStyling={page.childLinkStyling}
                  linkNameSignedIn={page.linkNameSignedIn}
                  authUser={authUser}
                  toggleNav={this.toggleNav}
                  key={page.linkName + index}
                  childLinks={page.childLinks}
                  linkName={page.linkName}
                  requireAuth={page.requireAuth}
                  requireAdmin={page.requireAdmin}
                  requireOrders={page.requireOrders}
                  requireTuition={page.requireTuition}
                  requireTournaments={page.requireTournaments}
                  requireEvents={page.requireEvents}
                  hideWithAuth={page.hideWithAuth}
                  showExperimental={showExperimental}
                  link={page.link}
                  navDarkActive={props.navDarkActive}
                />
              ))}
            </ul>
          </MobileNav>
        )}
      </nav>
    );
  }
}

Navigation.propTypes = {
  navDarkActive: PropTypes.bool.isRequired,
  showCart: PropTypes.bool.isRequired,
  showExperimental: PropTypes.bool.isRequired
};

export default Navigation;
